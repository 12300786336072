<template>
  <div>
    <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
    </ly-searchList>
    <div class="m-t1 tableBox">
      <ly-table :columns="columns" ref="tableRef" rowKey="instanceId" size="small" :data="data" :pagination="pagination" @pageChange="pageChange" :loading="loading">
        <template #instanceName="{ record }">
          <a @click="customRow(record)">{{ record.instanceName }}</a>
         </template>
        <template #instanceId="{ record }">
          <div class="flex "> {{ record.instanceId }}
          <a-typography-paragraph :copyable="{ text: record.instanceId }">
          </a-typography-paragraph></div>
        </template>
        <template #summary="{ record }">
          <div v-for="(item, index) in record.summary" :key="index">
            <div class="flex">
              <div style="text-align: right;width: 100px;color:#6B778C">{{ item.label }}:</div>
              <div class="m-l1">{{ item.value }}</div>
            </div>
          </div>
        </template>
        <template #flowInstanceStatus="{ record }">
          <span :style="{ color: statusType[record.flowInstanceStatus.value] }">{{ record.flowInstanceStatus.label }}</span>
        </template>
        <template #nodeRemark="{ record }">
          <span :style="{ color: '#f70303' }">{{record.nodeRemark }}</span>
        </template>
      </ly-table>
    </div>
    <a-modal v-model:visible="modalVisible" title="详情" width="80%" :maskClosable="false" footer="" destroyOnClose centered :bodyStyle="{ height: '70vh', overflow: 'auto', background: '#f0f2f5', padding: '0 24px', }">
      <FlowDetails @processingStatus="processingStatus" v-if="modalVisible" :flowId="flowId" :type="1"></FlowDetails>
    </a-modal>
  </div>
</template>
   
<script setup>
import { onMounted, ref } from 'vue'
import { iptData, columns } from './myPending'
import FlowDetails from '../components/flowDetails.vue'
import { flowPageTodo } from '@/api/flow/flow'
import { cloneDeep } from 'lodash-es';
import { transitionTimeYMD } from '@/utils/util'
const statusType = {
  0: '#26a5fe',
  1: '#1890FF',
  2: '#f9b603',
  3: '#88888',
  5: '#F70303',
  6: '#1BBA12'
}
const modalVisible = ref(false)
const data = ref([])
const flowId = ref('')
const loading = ref(false)
const searchMsg = ref({})
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
//分页事件
const pageChange = (e) => {
  pagination.value = e
  reqFlowPageTodo()
}
const searchAdd = (e) => {
  let msg = cloneDeep(e)
  msg.submitStartTime = transitionTimeYMD(msg.submitStartTime)
  msg.submitEndTime = transitionTimeYMD(msg.submitEndTime)
  msg.doneStartTime = transitionTimeYMD(msg.doneStartTime)
  msg.doneEndTime = transitionTimeYMD(msg.doneEndTime)
  searchMsg.value = msg
  pagination.value.current = 1
  reqFlowPageTodo()
}
const resetBtn = () => {
  searchMsg.value = {}
  pagination.value.current = 1
  reqFlowPageTodo()
}
const customRow = (record) => {
  flowId.value = record.instanceId
  modalVisible.value = true
}
const reqFlowPageTodo = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize
  }
  Object.assign(msg, searchMsg.value)
  flowPageTodo(msg).then(res => {
    if (res.code !== 10000) return
    data.value = res.data.records
    pagination.value.total = res.data.total
    console.log(res);
  }).finally(() => {
    loading.value = false
  })
}
const processingStatus = () => {
  reqFlowPageTodo()
}
onMounted(() => {
  reqFlowPageTodo()
})
</script>

<style lang="less" scoped>
.tableBox {
  padding: 15px;
  background: #ffffff;
  min-height: calc(100vh - 230px);
}
</style>